import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

export type ColumnFilterType =
    | 'contains'
    | 'does_not_contain'
    | 'equals'
    | 'does_not_equal'
    | 'begins_with'
    | 'ends_with'
    | 'blank'
    | 'not_blank'
    | 'greater_than'
    | 'less_than'
    | 'greater_than_or_equal_to'
    | 'less_than_or_equal_to'
    | 'between';

export type ColumnFilterEvent = {
    query: string;
    type: ColumnFilterType;
};

export type ColumnDataType = 'string' | 'number' | 'date';

export type ColumnFilterConfig = {
    dataType: ColumnDataType;
    disabled: boolean;
};

export const NON_CONTEXTUAL_FILTER_TYPES: ColumnFilterType[] = ['blank', 'not_blank'];

@Component({
    templateUrl: './column-filter.component.html',
    selector: 'app-column-filter',
    styleUrls: ['./column-filter.component.less'],
})
export class ColumnFilterComponent implements OnInit, OnDestroy {
    @Input() public columnFilterConfig: ColumnFilterConfig = {
        dataType: 'string',
        disabled: false,
    };

    @Output() public onFilter: EventEmitter<ColumnFilterEvent> = new EventEmitter<ColumnFilterEvent>();

    @ViewChild('filterContainer') filterContainer: ElementRef<HTMLDivElement> | undefined;
    @ViewChild('filterButton') filterButton: ElementRef<HTMLDivElement> | undefined;

    private _showFilter = false;

    public get showFilter() {
        return this._showFilter;
    }

    public set showFilter(value: boolean) {
        this._showFilter = value;
        this._cd.detectChanges();

        if (value) {
            this.determineFilterContainerPosition();
            this.addEventListeners();
        } else {
            this.removeEventListeners();
        }
    }

    public filterTypeOptionsMap: Partial<Record<ColumnFilterType, string>> = {};
    public filterTypes: ColumnFilterType[] = [];
    public selectedFilterType: ColumnFilterType = 'contains';

    public query: string | undefined;

    public isFilterApplied = false;

    constructor(private _cd: ChangeDetectorRef) {
        this.determineClickAway = this.determineClickAway.bind(this);
        this.determineFilterContainerPosition = this.determineFilterContainerPosition.bind(this);
    }

    public ngOnInit(): void {
        this.filterTypeOptionsMap = this.getFilterTypes();
        this.filterTypes = Object.keys(this.filterTypeOptionsMap) as ColumnFilterType[];
        this.selectedFilterType = this.filterTypes[0];
    }

    public getFilterTypes(): Partial<Record<ColumnFilterType, string>> {
        if (this.columnFilterConfig.dataType === 'number' || this.columnFilterConfig.dataType === 'date') {
            return {
                equals: 'Equals',
                does_not_equal: 'Does Not Equal',
                greater_than: 'Greater Than',
                less_than: 'Less Than',
                greater_than_or_equal_to: 'Greater Than Or Equal To',
                less_than_or_equal_to: 'Less Than Or Equal To',
                between: 'Between',
                blank: 'Blank',
                not_blank: 'Not Blank',
            };
        } else {
            return {
                contains: 'Contains',
                does_not_contain: 'Does Not Contain',
                equals: 'Equals',
                does_not_equal: 'Does Not Equal',
                begins_with: 'Begins With',
                ends_with: 'Ends With',
                blank: 'Blank',
                not_blank: 'Not Blank',
            };
        }
    }

    public addEventListeners(): void {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('resize', this.determineFilterContainerPosition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('scroll', this.determineFilterContainerPosition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.body.addEventListener('click', this.determineClickAway);
    }

    public removeEventListeners(): void {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.removeEventListener('resize', this.determineFilterContainerPosition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.removeEventListener('scroll', this.determineFilterContainerPosition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.body.removeEventListener('click', this.determineClickAway);
    }

    public determineClickAway(event: PointerEvent): void {
        if (!this.filterButton?.nativeElement || !this.filterContainer?.nativeElement) {
            return;
        }

        const button = this.filterButton.nativeElement;
        const container = this.filterContainer.nativeElement;

        const isClickAway =
            !container.contains(event.target as Node) &&
            container !== event.target &&
            !button.contains(event.target as Node) &&
            button !== event.target;

        if (isClickAway) {
            this.showFilter = false;
        }
    }

    public determineFilterContainerPosition(): void {
        if (!this.filterButton?.nativeElement || !this.filterContainer?.nativeElement) {
            return;
        }

        const button = this.filterButton.nativeElement;
        const container = this.filterContainer.nativeElement;

        const buttonRect = button.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        container.style.position = 'fixed';

        const totalX = buttonRect.x + containerRect.width;
        const edgePadding = 5;

        container.style.left = totalX > window.innerWidth ? `${buttonRect.x - (totalX - window.innerWidth - edgePadding)}px` : `${buttonRect.x}px`;

        container.style.top = `${buttonRect.y + buttonRect.height}px`;
    }

    public onSearch(): void {
        if (this.columnFilterConfig.dataType === 'number' && this.query && isNaN(Number(this.query))) {
            this.query = '';
            return;
        }

        this.onFilter.emit({
            query: this.query,
            type: this.selectedFilterType,
        });

        this.isFilterApplied = !!this.query || NON_CONTEXTUAL_FILTER_TYPES.includes(this.selectedFilterType);
    }

    public ngOnDestroy(): void {
        this.removeEventListeners();
    }
}
