<div *ngIf="!columnFilterConfig.disabled" #filterButton class="cursor-pointer" (click)="showFilter = !showFilter">
    <span [ngStyle]="{ color: isFilterApplied ? 'red' : 'orange' }" class="fa fa-filter"></span>
</div>

<div *ngIf="!columnFilterConfig.disabled" #filterContainer [ngStyle]="{ display: showFilter ? 'block' : 'none' }" class="column-filter-container">
    <div class="mb-10">
        <select class="filter-type-select w-full text-gray" [(ngModel)]="selectedFilterType" (change)="onSearch()">
            <option *ngFor="let option of filterTypes" [value]="option">{{ filterTypeOptionsMap[option] }}</option>
        </select>
    </div>
    <mt-search-bar
        *ngIf="columnFilterConfig.dataType === 'string' || columnFilterConfig.dataType === 'number'"
        [(ngModel)]="query"
        (searched)="onSearch()"
    ></mt-search-bar>
    <input class="text-gray w-full" *ngIf="columnFilterConfig.dataType === 'date'" type="date" [(ngModel)]="query" (change)="onSearch()" />
</div>
